$background-color: #0f0f0f;
$text-color: #f0ede7;
$text-color2: #171718;
$text-color3:#020202;
$bg2: #9bed55;

.contact-container{
  width: 100%;
  height: auto;
  background-color: $background-color;
  font-family: "DM Sans", sans-serif;
  ;
}


.contact-info{
    width: 50%;
    height: 100%;
    margin: 0 auto;
    padding:5rem 0;
    padding-bottom: 7rem;
}

.contact-links{
  display: flex;
  justify-content: space-between;
  height: 15rem;
}
.contact-links .link{
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  color: $text-color;
}
.link a{
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: $bg2;
  border-radius: 50%;
}
.link i{
 color: $background-color;
 font-size: 2rem;
}
.link h4{
  font-size: 1.4rem;
  margin-bottom: 0.6rem;
  margin-top: 0.5rem;
}
.link p{
  font-size: 1.4rem;
}

.contact-form{
  width: 50%;
}
.contact-inputs{
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.contact-inputs input {
  width: 100%;
  height: 40px;
  font-size: 1.2rem;
  padding: 10px;
  outline: none;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: $text-color3;
  border: 1px solid $text-color3;
color: $text-color;
}
input::placeholder {
  text-transform: capitalize;
  color: #999; /* Example color */
  font-style: italic; /* Example font style */
}


.contact-inputs textarea {
  height: 10rem;
  resize: none;
  outline: none;
  padding: 10px;
  font-size: 1.2rem;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: $text-color3;
  border: 1px solid $text-color3;
  color: $text-color;
}
textarea::placeholder {
  text-transform: capitalize;
  color: #999; /* Example color */
  font-style: italic;
}


.contact-inputs input[type="submit"] {
  font-size: 1.4rem;
  background-color: $text-color;
  color:$text-color2;
  border: none;
  padding: 8px 20px;
  border-radius: 10px;
  text-decoration: none;
  margin-right: 1rem;
  background-color: $bg2;
}
@media screen and (max-width: 500px) {

.contact-links{
  display: none;
}
}

@media screen and (max-width: 896px) {
  .contact-container{
    padding: 8rem 0;
  }
  .contact-info{
      width: 90%;
      padding:0 ;
  }
  

  .link a{
    width: 4rem;
    height: 4rem;
   
  }
  .link i{
   font-size: 1.6rem;
  }
  .link p{
    font-size: 1.2rem;
  }
  
  .contact-form{
    width: 100%;
  }

}