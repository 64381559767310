
$background-color: #0f0f0f;
$text-color: #f0ede7;
$text-color2: #171718;
$text-color3:#acacac;
$bg2: #9bed55;
.testimonials {
  width:100% ;
  height: 100%;
  background-color: $background-color;
  padding: 8rem 1rem;
  margin: 0 auto;
}
.heading {
    padding: 1rem 0;
    font-size: 3rem;
    text-align: center;
    color: $text-color;
    font-weight: 500;
    text-transform: capitalize;
    font-family: "DM Sans", sans-serif;
    margin-bottom: 4rem;
  }
  .heading i{
    font-size: 3rem;
    color: $bg2;
    padding-left: 1rem;
  }
  



.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background-position: center;
  background-size: cover;
  width: 350px;
  height: 350px;
  margin-bottom: 2rem;
}

.swiper-slide img {
  display: block;
  width: 100%;
}
.swiper-slide video {
  width: 100%;object-fit: cover;
  height: 100%;
}

.swiper-pagination .swiper-pagination-bullet {
      background-color: $text-color3;
    }
    .slider-controler {
        width: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20rem;
        bottom:0;
       
      }
     .slider-controler .slider-arrow{
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        background-color: $text-color;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
     }     
      .slider-controler .slider-arrow ion-icon {
        font-size: 2rem;
        color:$background-color;
      }
      

@media screen and (max-width: 896px) {
  
.testimonials {
  padding: 8rem 0 ;
}
.heading {
  font-size: 2.4rem;
    
  }
  .heading i{
    font-size: 2.4rem;
    
  }
  .swiper-slide {
    width: 300px;
    height: 300px;
  }
}