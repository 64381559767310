$background-color: #0f0f0f;
$text-color: #f0ede7;
$text-color2: #171718;
$text-color3:#acacac;
$bg2: #9bed55;
.header{
  height: 80px;
  width: 100%;
  background-color: $background-color ;
  font-family: "DM Sans", sans-serif;
  z-index: 1002;
}

.navbar{
  width:80%;
  height: 100%;
  margin-left: 12rem ;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo{
  width: 45px;
  margin-left: -0.4rem;
  
}
.logo img{
  width: 100%;
}
.menu-list{
  display: flex;
}
.menu-list li{
  list-style: none;
}
.menu-list li a{
  color:$text-color ;
  list-style: none;
  margin-right:3.5rem ;
  font-size: 1.4rem;
  text-decoration: none;
  transition: 0.2s ease-in;
}
.menu-list li a:hover{
  color: $bg2;
}

.sendmail-btn{
   font-size: 1.4rem;
   background-color: $text-color;
   color:$text-color2;
   border: none;
   padding: 8px 20px;
   border-radius: 10px;
   text-decoration: none;
   transition: 0.1s linear;
}
.sendmail-btn:hover{
  color: $background-color;
  background-color: $bg2;
}
.menu-dropdown{
  display: none;
}


@media screen and (max-width: 768px) {

  .header{
    position:fixed;
  }
  .navbar{
    width:90%;
    margin:0 auto ;
  }
  .logo{
    width: 45px;
    margin-left: -0.4rem;
    
  }
  .logo img{
    width: 100%;
  }
  .menu-list{
    display: none;
  }
  .sendmail-btn{
    display: none;
  }
  .navbar .menu-dropdown i{
    font-size: 25px;
    color: $text-color;
  }
  .menu-dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-show {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: $text-color2;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1003;
    transition: right 0.5s linear;
    
}

.dropdown-show a {
    color:$text-color;
    padding: 15px 20px;
    font-size: 15px;
    text-decoration: none;
    display: block;
}
  
}

@media screen and (min-width: 768px) and (max-width: 896px) {
  .navbar{
    width:90%;
    margin:0 auto ;
  }
}
