$background-color: #0f0f0f;
$text-color: #f0ede7;
$text-color2: #171718;
$text-color3:#acacac;
$bg2: #9bed55;
.home{
    width:100%;
    height:100%;
    padding-bottom: 8rem;
    background-color:$background-color ;
}
.flex-section{
    width:90%;
    margin-left: 12rem;
    display: flex;
    align-items: center;
    color:$text-color;
    padding-top: 4rem;
}
.left-section{
  font-family: "DM Sans", sans-serif;
    width:35%;
    height: 100%;
}
.left-section h1{
    font-family: "Anton", sans-serif;
    font-size: 7rem;
    line-height: 7.2rem;
    text-transform: uppercase;
    letter-spacing: 2px;
	color: $text-color;
    position: relative;

}
.left-section h1::after{
    content: "Media";
    font-family: "Anton", sans-serif;
    font-size: 7rem;
    line-height: 7.2rem;
    text-transform: uppercase;
    letter-spacing: 2px;
	color: $text-color;
    position: absolute;
    bottom: 0;
    left: 0;
    color: $bg2
    ;
	animation: animate 2s ease-in-out infinite;
}

@keyframes animate {
	0%,
	100% {
		clip-path: polygon(
			0% 45%,
			16% 44%,
			33% 50%,
			54% 60%,
			70% 61%,
			84% 59%,
			100% 52%,
			100% 100%,
			0% 100%
		);
	}

	50% {
		clip-path: polygon(
			0% 60%,
			15% 65%,
			34% 66%,
			51% 62%,
			67% 50%,
			84% 45%,
			100% 46%,
			100% 100%,
			0% 100%
		);
	}
}

.left-section p{
    color: $text-color3;
    margin: 4rem 0;
    line-height: 2rem;
    font-weight: 600;
    font-size: 1.4rem;
}

.left-section .ls-btn1{
   font-size: 1.4rem;
   background-color: $text-color;
   color:$text-color2;
   border: none;
   padding: 8px 20px;
   border-radius: 10px;
   text-decoration: none;
   margin-right: 1rem;
   background-color: $bg2;
   
}
.left-section .ls-btn1:hover{
    color: $background-color;
}

.left-section .ls-btn1 i{
    padding-left: 5px;
}


.left-section .ls-btn2{
    font-size: 1.4rem;
    background-color: $text-color;
    color:$text-color2;
    border: none;
    padding: 8px 20px;
    border-radius: 10px;
    text-decoration: none;
 }

 .right-section{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 65%;

 }
 .right-section .container{
    display: flex;
 }
 .right-section .container:nth-child(1){
    margin-left: 7rem;
 }
 .right-section .container:nth-child(3){
    margin-left: 7rem;
 }
 .right-section .video-container{
    width: 170px;
    height: 110px;
    border-radius: 50px ;
    margin:0px 2rem 2rem 0px;
 }
 .right-section .video-container video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
 }
 .right-section .video-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
 }


 @media screen and (max-width: 896px) {
    .home{
    padding: 80px 0;

    }
    .flex-section{
        width:100%;
        margin:0;
        
    }
    .left-section{
        width:90%;
        margin: 0 auto;
        
    }
    .left-section h1{
        font-size: 4rem;
        line-height: 4.2rem;
    
    }
    .left-section h1 br{
        display: none;
    }
    .left-section h1::after{
        content: "Hitch Media";
        font-size: 4rem;
        line-height: 4.2rem;
        top: 0;
        left: 0;
    }
    
   
    .left-section p{
        margin: 6rem 0;
    }
    .right-section{
        display: none;
    }
 }
 @media screen and (min-width: 896px) and (max-width:1100px) {
    .home{
        overflow-x: hidden;
    }
 }
