@import url('./pages/header.scss');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 10px;
}
html {
  scroll-behavior: smooth;
}

body {
  scroll-behavior: smooth;
}
