$background-color: #0f0f0f;
$text-color: #f0ede7;
$text-color2: #171718;
$text-color3:#acacac;
$bg2: #9bed55;

.footer{
    width: 100%;
    height: 15rem;
    background-color: $background-color;
}
.footer-line{
    width: 90%;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.336);
    height: 1px;
}
.footer-container{
    width:80%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2.5rem;
}
.footer-links{
    display: flex;
    gap: 2rem;
    
}
.footer-links .smedia{
    width: 40px;
    height: 40px;
    border: 1px solid $text-color;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s ease-in;
}
.footer-links .smedia:hover{
    background-color: $bg2;
    border:  1px solid $bg2;
}
.footer-links .smedia:hover i{
    color: $background-color;
}

.footer-links a{
    text-decoration: none;
    color: $text-color;
}
    .footer-links a i{
        font-size: 2rem;
    transition: 0.2s ease-in;

    }

.footer-copyright{
        color: $text-color;
        text-transform: capitalize;
        font-size: 1.4rem;
        letter-spacing: 1px;
}